import Default from 'layouts/auth/types/Default';
import React from 'react';
import PinInput from 'react-pin-input';
import api from '../../api.js';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';

function VerificationDefault() {
  const auth = useAuth();
  const handleSubmit = async (pin) => {
    const id = auth.user.id;
    api
      .post('/2fa/verify', { id, pin })
      .then(({ data }) => {
        if (data.status === 'success') {
          auth.two_factor_auth();
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Invalid 2FA Code`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePinComplete = (value) => {
    handleSubmit(value);
  };
  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center dark:!bg-navy-900 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[16vh] flex flex-col rounded-[20px] bg-white pb-16 pt-12 dark:!bg-navy-900 md:pr-6 lg:pr-0 xl:pr-24">
            <form
              id="pinForm"
              name="pinForm"
              onSubmit={handleSubmit}
              className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
            >
              <h2 className="mt-1 text-4xl font-bold text-navy-700 dark:text-white">
                2-Step Verification
              </h2>
              <p className="ml-1 mt-[15px] text-[15px] text-gray-600">
                Please enter your 2-Step Verification code from google
                authenticator to unlock!
              </p>
              {/* verification */}
              <div className="mb-4 mt-7 flex w-full items-center justify-center">
                <PinInput
                  length={6}
                  initialValue=""
                  type="numeric"
                  inputMode="number"
                  style={{ marginBottom: '10px' }}
                  inputStyle={{
                    borderWidth: '2px',
                    width: '60px',
                    height: '60px',
                    borderColor: '#FFFFFF',
                    borderRadius: '16px',
                    color: '#FFFFFF',
                    fontSize: '36px',
                  }}
                  inputFocusStyle={{
                    borderColor: '#4318FF',
                    borderWidth: '3px',
                  }}
                  onComplete={handlePinComplete}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
            </form>
            <p className="mt-4 text-sm font-medium text-navy-700 dark:text-gray-500">
              Having 2FA Issues?
              <a href="/auth/2fa/reset">
                <span className="text-sm font-medium text-brand-500 dark:text-white">
                  {' '}
                  Reset Account 2FA{' '}
                </span>
              </a>
            </p>
          </div>
        </div>
      }
    />
  );
}

export default VerificationDefault;
