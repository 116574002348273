import React, { useState, useEffect } from 'react';
import { useAdminAuth } from 'contexts/AuthContextAdmin';
import CouponsTable from './components/CouponsTable';
import api from '../../../api';
import { Spinner } from '@chakra-ui/spinner';

const Coupons = () => {
  useAdminAuth();
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  useEffect(() => {
    api
      .get(`/admin/coupons/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setCoupons(response.data.coupons);
        }
      })
      .catch((error) => {
        console.error('Error getting Coupons: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tableColumns = [
    {
      Header: 'COUPON CODE',
      accessor: 'code',
    },
    {
      Header: 'COINS',
      accessor: 'coins',
    },
    {
      Header: 'CURRENT USES',
      accessor: 'current_uses',
    },
    {
      Header: 'MAX USES',
      accessor: 'max_uses',
    },
    {
      Header: 'EXPIRATION DATE',
      accessor: 'expiration_date',
    },
    {
      Header: 'ACTIONS',
      accessor: 'actions',
    },
  ];

  return (
    <div className="h-full w-full rounded-md">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <div className="mt-3 h-full w-full rounded-[14px]  ">
            <CouponsTable columnsData={tableColumns} tableData={coupons} />
          </div>
        </>
      )}
    </div>
  );
};

export default Coupons;
