import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../../api.js';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';

const LVForm = (props) => {
  const { name } = props;
  const auth = useAuth();
  const id = auth.user.id;
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [earning, setEarning] = useState({});
  useEffect(() => {
    api
      .get(`/settings/earning/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setEarning(response.data.settings);
        }
      })
      .catch((error) => {
        console.error('Error getting Earning Information: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const verifyLinkvertiseHash = async (hash) => {
    api
      .post('/earn/lv_verify', { id, hash })
      .then(({ data }) => {
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `You have sucessfully completed the challenge link for 3 Coins.`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/earn/lv';
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Completing Challenge was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/earn/lv';
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Completing Challenge was not successful! Error: Unknown.`,
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/earn/lv';
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const hash = queryParams.get('hash');

    if (hash) {
      verifyLinkvertiseHash(hash);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div className="flex h-full w-full flex-col items-center rounded-[20px] px-[1px] pt-[3px] dark:bg-navy-900 sm:px-[150px] lg:px-[0px] xl:px-[35px]">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <h1 className="mx-auto mt-14 text-[35px] font-bold text-navy-700 dark:text-white md:px-[10px] lg:text-[30px] xl:text-[53px]">
            {name}
          </h1>
          <p className="mb-9 ml-1 text-base text-gray-600">
            Complete LinkVertise Link Challenges to Earn Coins!{' '}
            {earning.linkvertise.lv_coins} Coins Per Completion.
          </p>
          <a href={earning.linkvertise.lv_url}>
            <button className="w-full rounded-xl bg-brand-500 p-3 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              LinkVertise Challenge
            </button>
          </a>
        </>
      )}
    </div>
  );
};

export default LVForm;
