import SearchIcon from 'components/icons/SearchIcon';
import React, { useMemo, useState } from 'react';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import api from '../../../../api';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';

function CouponsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [buttonloading, setButtonLoading] = useState(false);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,
    setPageSize,

    setGlobalFilter,
    state,
  } = tableInstance;

  const { pageSize } = state;

  const createCoupon = () => {
    window.location.replace(`/admin/coupon/create`);
  };

  const editCoupon = (code) => {
    window.location.replace(`/admin/coupon/update?code=${code}`);
  };

  const deleteCoupon = (code) => {
    setButtonLoading(true);
    api
      .delete(`/admin/coupon/delete/${code}`)
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `Coupon Deleted!`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Deleting Coupon was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Deleting Coupon was not successful! Please contact support.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="h-full w-full">
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-[400px] max-w-full items-center rounded-md p-[8px] pt-[20px] xl:ml-3"
      >
        <div className="flex h-[38px] w-[400px] flex-grow items-center rounded-md bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search...."
            className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
        <button
          onClick={() => createCoupon()}
          className="rounded-xl bg-gradient-to-br from-[#0083FE] to-[#00FFF0] px-12 py-1 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#0083FE]/50"
        >
          Create Coupon
        </button>
      </div>

      {/* table */}
      <div className="mt-[14px] h-full w-full overflow-x-scroll 2xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full font-dm">
          <thead className="w-full">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="items-center border-b border-gray-200 dark:!border-white/10"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-[27px] pb-[12px] pt-[32px] text-start text-xs tracking-wide text-gray-600"
                    key={index}
                  >
                    <div className="text-xs font-bold">
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  className="border-b border-gray-200 dark:!border-white/10"
                  {...row.getRowProps()}
                  key={index}
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'COUPON CODE') {
                      data = (
                        <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'COINS') {
                      data = (
                        <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'CURRENT USES') {
                      data = (
                        <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'MAX USES') {
                      data = (
                        <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'EXPIRATION DATE') {
                      data = (
                        <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'ACTIONS') {
                      data = (
                        <div className="flex flex-row flex-wrap items-center gap-3">
                          <button
                            onClick={() => editCoupon(cell.row.original.code)}
                            className="rounded-xl bg-gradient-to-br from-[#0083FE] to-[#00FFF0] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#0083FE]/50"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => deleteCoupon(cell.row.original.code)}
                            className="rounded-xl bg-gradient-to-br from-[#FF416C] to-[#FF4B2B] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FF416C]/50"
                          >
                            Delete
                          </button>
                        </div>
                      );
                    } else if (
                      cell.column.Header === 'ACTIONS' &&
                      buttonloading === true
                    ) {
                      data = (
                        <div className="flex flex-row flex-wrap items-center gap-3">
                          <button
                            onClick={() => editCoupon(cell.row.original.code)}
                            className="rounded-xl bg-gradient-to-br from-[#0083FE] to-[#00FFF0] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#0083FE]/50"
                            disabled="true"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => deleteCoupon(cell.row.original.code)}
                            className="rounded-xl bg-gradient-to-br from-[#FF416C] to-[#FF4B2B] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FF416C]/50"
                            disabled="true"
                          >
                            <Spinner
                              size="xl"
                              thickness="2px"
                              style={{ height: 14, width: 14 }}
                            />
                          </button>
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="mt-[20px] px-4 py-[16px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-3">
          <p className="> Show rows per page text-sm text-gray-700">
            Show rows per page{' '}
          </p>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-[32px] w-[75px] rounded-[20px] border border-gray-200 px-2 text-gray-700 dark:!border-white/10 dark:bg-navy-800"
            name=""
            id=""
          >
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-md bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
          >
            <MdChevronLeft />
          </button>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-md bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default CouponsTable;
