import SetUp from './TwoFactor_actions.jsx';
import Card from 'components/card';
import { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import api from '../../../../api';

const TwoFactor = () => {
  const auth = useAuth();
  const id = auth.user.id;
  const [twoFactor, setTwoFactor] = useState(false);
  useEffect(() => {
    api
      .post('/2fa/check', { id })
      .then(({ data }) => {
        if (data.status === 'success') {
          setTwoFactor(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <Card extra={'w-full px-8 py-8'}>
      {/* Header */}
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Two-Factor Authentication (Google Authenticator)
        </h4>
        {twoFactor ? (
          <div className="mt-1 flex items-center justify-center rounded-[10px] bg-green-100 px-3 py-1 text-sm font-bold uppercase text-green-500 dark:bg-green-50">
            Enabled
          </div>
        ) : (
          <div className="mt-1 flex items-center justify-center rounded-[10px] bg-red-100 px-3 py-1 text-sm font-bold uppercase text-red-500 dark:bg-red-50">
            Disabled
          </div>
        )}
      </div>
      {/* content */}
      {twoFactor ? (
        <SetUp
          name="Authenticator App"
          value="Configured"
          actionName="Disable"
          py="py-6"
          border="border-b"
          id={id}
          action="disable"
        />
      ) : (
        <SetUp
          name="Authenticator App"
          value="Not configured"
          actionName="Set up"
          py="py-6"
          border="border-b"
          id={id}
          action="enable"
        />
      )}
    </Card>
  );
};

export default TwoFactor;
