import axios from 'axios';

// const api = axios.create({
//   baseURL: 'http://127.0.0.1:8081/api',
//   withCredentials: 'include',
// });

const getApiBaseUrl = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}/api`;
};

const api = axios.create({
  baseURL: getApiBaseUrl(),
  withCredentials: 'include',
});

export default api;
