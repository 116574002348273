import React, { useState, useEffect } from 'react';
import LeaderboardTable from './components/LeaderboardTable';
import { Spinner } from '@chakra-ui/spinner';
import api from '../../api.js';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [leaderboard, setLeaderboard] = useState([]);
  const columns = [
    {
      Header: 'NAME',
      accessor: 'fullname',
    },
    {
      Header: 'COINS',
      accessor: 'coins',
    },
  ];
  useEffect(() => {
    api
      .get(`/earn/leaderboard`)
      .then((response) => {
        if (response.data.status === 'success') {
          setLeaderboard(response.data.leaderboard);
        }
      })
      .catch((error) => {
        console.error('Error getting Leaderboard: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="h-full w-full rounded-md">
      {/* Reserved for future statistics */}
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-md xl:flex-row">
          <div className="h-full w-full rounded-md">
            <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
              <div className="col-span-6 h-full w-full rounded-md 3xl:col-span-4">
                <div className="h-full w-full rounded-[14px]  ">
                  <LeaderboardTable
                    columnsData={columns}
                    tableData={leaderboard}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
