import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import React from 'react';
import api from '../../api';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import { useLocation } from 'react-router-dom';

function Reset2FA() {
  const location = useLocation();
  const [sbuttonloading, setSbuttonLoading] = React.useState(false);
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has('code') === true) {
    const code = queryParams.get('code');
    if (code) {
      api
        .post('/auth/2fa/set', { code })
        .then(({ data }) => {
          setSbuttonLoading(false);
          if (data.status === 'success') {
            Swal.fire({
              title: 'Success!',
              text: `2FA have been successfully reset!`,
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              window.location.replace('/auth/login');
            });
          } else if (data.status === 'error') {
            Swal.fire({
              title: 'Error!',
              text: `2FA reset was not successful! Error: ${data.message}`,
              icon: 'error',
              confirmButtonText: 'OK',
            });
          } else {
            Swal.fire({
              title: 'Error!',
              text: `2FA reset was not successful! Please contact your administrator.`,
              icon: 'error',
              confirmButtonText: 'OK',
            }).then(() => {
              window.location.replace('/auth/login');
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Swal.fire({
        title: 'Error!',
        text: `Invalid 2FA reset code was given!`,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then(() => {
        window.location.replace('/auth/login');
      });
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setSbuttonLoading(true);
    const form = new FormData(e.target);
    const email = form.get('email');

    api
      .post('/auth/2fa/reset', { email })
      .then(({ data }) => {
        setSbuttonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `2FA reset link have been emailed to you!`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.replace('/auth/login');
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `2FA reset was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Password reset was not successful! Please contact your administrator.`,
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.replace('/auth/login');
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[16vh] flex flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:max-w-[90%] lg:pr-0 xl:max-w-[42%] xl:pr-24">
            <form
              onSubmit={handleSubmit}
              className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
            >
              <h2 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
                Having issues with Two Factor Authentication?
              </h2>
              <p className="mb-8 ml-1 mt-[10px] text-base text-gray-600">
                No problem. Just let us know your email address and we'll email
                you a 2FA reset link that will allow you disable 2FA for your
                account.
              </p>
              {/* Email */}
              <InputField
                field_name="email"
                variant="auth"
                label="Email"
                placeholder="example@gmail.com"
                extra="mb-3"
                id="email"
                type="text"
              />
              {/* button */}
              {sbuttonloading ? (
                <button
                  className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled="true"
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Email 2FA Reset Link
                </button>
              )}
            </form>
          </div>
        </div>
      }
    />
  );
}

export default Reset2FA;
