import Card from 'components/card';
import InputField from 'components/fields/InputField';
import api from '../../../../api';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import React, { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';

const Information = () => {
  const auth = useAuth();
  const [buttonloading, setButtonLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const firstname = form.get('firstname');
    const lastname = form.get('lastname');
    const email = form.get('email');
    const password = form.get('cpassword');
    const id = auth.user.id;
    api
      .post('/account/set_profile', {
        id,
        firstname,
        lastname,
        email,
        password,
      })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          if (email === auth.user.email) {
            Swal.fire({
              title: 'Success!',
              text: `Profile Updated`,
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              window.location.reload();
            });
          } else {
            Swal.fire({
              title: 'Success!',
              text: `Profile Updated, please login with your new email.`,
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              auth.logout();
              window.location.replace('/auth/login');
            });
          }
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Updating Profile was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Updating Profile was not successful!`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Card extra={'w-full !p-5'}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Account Settings
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change user account information
        </p>
      </div>
      {/* inputs */}
      <form onSubmit={handleSubmit}>
        <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
          <InputField
            field_name="firstname"
            label="First Name"
            value={auth.user.firstname}
            id="firstname"
            type="text"
          />
          <InputField
            field_name="lastname"
            label="Last Name"
            value={auth.user.lastname}
            id="lastname"
            type="text"
          />
        </div>
        <InputField
          field_name="email"
          label="Email Address"
          value={auth.user.email}
          id="email"
          type="text"
        />
        <InputField
          field_name="cpassword"
          label="Your Current Account Password"
          placeholder="Confirm Your Current Password"
          id="cpassword"
          type="password"
        />
        <div className="mt-12 flex w-full justify-end">
          {buttonloading ? (
            <button
              className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              disabled="true"
            >
              <Spinner
                size="xl"
                thickness="2px"
                style={{ height: 14, width: 14 }}
              />
            </button>
          ) : (
            <button className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Update Profile
            </button>
          )}
        </div>
      </form>
    </Card>
  );
};

export default Information;
