import { Routes, Route, Navigate } from 'react-router-dom';

import DashboardLayout from 'layouts/dashboard';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import 'assets/css/Plugins.css';
import { useState, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from 'contexts/AuthContext';
import { AuthProviderAdmin } from 'contexts/AuthContextAdmin';
import { Helmet } from 'react-helmet';
import api from './api.js';
import { Spinner } from '@chakra-ui/spinner';

const App = () => {
  // Create a new context
  const [themeApp, setThemeApp] = useState({
    '--background-100': '#FFFFFF',
    '--background-900': '#070f2e',
    '--shadow-100': 'rgba(112, 144, 176, 0.08)',
    '--color-50': '#E9E3FF',
    '--color-100': '#C0B8FE',
    '--color-200': '#A195FD',
    '--color-300': '#8171FC',
    '--color-400': '#7551FF',
    '--color-500': '#422AFB',
    '--color-600': '#3311DB',
    '--color-700': '#2111A5',
    '--color-800': '#190793',
    '--color-900': '#11047A',
  });
  //eslint-disable-next-line
  const theme_red = {
    '--color-50': '#FCE8E8',
    '--color-100': '#FAD1D1',
    '--color-200': '#F4A4A4',
    '--color-300': '#EF7676',
    '--color-400': '#EA4848',
    '--color-500': '#E31A1A',
    '--color-600': '#B71515',
    '--color-700': '#891010',
    '--color-800': '#5B0B0B',
    '--color-900': '#2E0505',
    '--color-950': '#170303',
  };
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState({});
  useEffect(() => {
    api
      .get(`/settings/brand/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setBrand(response.data.settings);
        }
      })
      .catch((error) => {
        console.error('Error getting Branding Information: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  setTimeout(() => setThemeApp(theme_red), 0);
  const [mini, setMini] = useState(false);
  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    document.body.classList.add('dark');
    //eslint-disable-next-line
  }, [themeApp]);
  return (
    <ChakraProvider theme={themeApp}>
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <Helmet>
            {/* Your global script */}
            <link rel="icon" href={brand.assets.logo_url} />
            <title>{brand.company_name}</title>
            <script
              type="text/javascript"
              src="//pl24150408.cpmrevenuegate.com/44/36/3c/44363c1898ec7a699e1b58248f3f40ba.js"
            ></script>{' '}
          </Helmet>
          <AuthProvider>
            <AuthProviderAdmin>
              <Routes>
                <Route path="auth/*" element={<AuthLayout />} />
                <Route
                  path="admin/*"
                  element={
                    <AdminLayout
                      setMini={setMini}
                      mini={mini}
                      theme={themeApp}
                      setTheme={setThemeApp}
                    />
                  }
                />
                <Route
                  path="/*"
                  element={
                    <DashboardLayout
                      setMini={setMini}
                      mini={mini}
                      theme={themeApp}
                      setTheme={setThemeApp}
                    />
                  }
                />
                <Route
                  path="/"
                  element={<Navigate to="/dashboard" replace />}
                />
                <Route
                  path="/admin"
                  element={<Navigate to="/admin/dashboard" replace />}
                />
              </Routes>
            </AuthProviderAdmin>
          </AuthProvider>
        </>
      )}
    </ChakraProvider>
  );
};

export default App;
