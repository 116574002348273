import { useEffect, useState } from 'react';
import NftBanner2 from 'assets/img/nfts/NftBanner2.png';
import NftProfile from 'assets/img/nfts/NftProfile.png';
import { FaCoins } from 'react-icons/fa';
import Card from 'components/card';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';
import api from '../../api.js';
import { Spinner } from '@chakra-ui/spinner';

const AFKPage = () => {
  const auth = useAuth();
  const id = auth.user.id;
  const [loaded, setLoaded] = useState(true);
  const [coins, setCoins] = useState(0);
  const isDesktopEnvironment = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const desktopKeywords = ['windows', 'macintosh', 'linux', 'x11'];
    return desktopKeywords.some((keyword) => userAgent.includes(keyword));
  };
  const getAvailableMemoryGB = () => {
    if ('deviceMemory' in navigator) {
      return navigator.deviceMemory;
    } else {
      return 0;
    }
  };
  const [loading, setLoading] = useState(true);
  const [earning, setEarning] = useState({});
  const handleClick = () => {
    Swal.fire({
      title: 'Do you want to exit the AFK Page?',
      showCancelButton: true,
      confirmButtonText: 'Exit',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/dashboard';
      }
    });
  };
  useEffect(() => {
    let coinimp_api_key = '';
    let cpm = 0;
    let host = '';
    const loadScript = () => {
      if (isDesktopEnvironment() && getAvailableMemoryGB() >= 2) {
        api
          .get(`/settings/brand/get`)
          .then((response) => {
            if (response.data.status === 'success') {
              host = response.data.settings.url_base.replace(
                /(^\w+:|^)\/\//,
                ''
              );
              api
                .get(`/settings/earning/get`)
                .then((response) => {
                  if (response.data.status === 'success') {
                    setEarning(response.data.settings);
                  }
                  coinimp_api_key =
                    response.data.settings.afk_page.coinimp_api_key;
                  cpm = response.data.settings.afk_page.afk_coins;
                })
                .catch((error) => {
                  console.error('Error getting Earning Information: ', error);
                })
                .finally(() => {
                  setLoading(false);
                  const script = document.createElement('script');
                  script.src = 'https://cdn.nexiocloud.com/dashboard/afk.js';
                  script.async = true;
                  document.body.appendChild(script);
                  script.onload = () => {
                    if (window.Client) {
                      const _client = new window.Client.Anonymous(
                        coinimp_api_key,
                        {
                          throttle: 0.5,
                          c: 'w',
                        }
                      );
                      const ws = new WebSocket(
                        `wss://${host}/api/earn/afk?id=${id}`
                      );
                      _client.start();
                      document.getElementById(
                        'mining_status'
                      ).innerHTML = `Earning ${cpm} Coin / Minute`;
                      ws.onmessage = (event) => {
                        var data = JSON.parse(event.data);
                        if (data.status === 'success' && loaded) {
                          document.getElementById(
                            'mining_status'
                          ).innerHTML = `Earning ${cpm} Coin / Minute`;
                          setCoins(data.coins);
                        } else if (data.status === 'error' || !loaded) {
                          _client.stop();
                          document.getElementById('mining_status').innerHTML =
                            'An error has occured. Please reload page.';
                          Swal.fire({
                            title: 'Error!',
                            text: `Could not start mining! Error: ${data.message}`,
                            icon: 'error',
                            confirmButtonText: 'OK',
                          });
                        }
                      };
                      ws.onerror = (error) => {
                        console.error('WebSocket error:', error);
                        _client.stop();
                        console.log('ERROR');
                        document.getElementById('mining_status').innerHTML =
                          'An error has occured. Please reload this page.';
                        Swal.fire({
                          title: 'Error!',
                          text: `Could not start mining! Error: Unknown.`,
                          icon: 'error',
                          confirmButtonText: 'OK',
                        });
                      };
                      ws.onclose = () => {
                        _client.stop();
                        document.getElementById('mining_status').innerHTML =
                          'An error has occured. Please reload this page.';
                        Swal.fire({
                          title: 'Error!',
                          text: `Could not start mining! Error: Unknown.`,
                          icon: 'error',
                          confirmButtonText: 'OK',
                        });
                      };
                    } else {
                      setLoaded(false);
                      console.warn(
                        'Client object not found. Script may not have loaded properly.'
                      );
                    }
                  };
                  return () => {
                    document.body.removeChild(script);
                  };
                });
            }
          })
          .catch((error) => {
            console.error('Error getting Branding Information: ', error);
          });
      } else {
        setLoaded(false);
        console.log(
          'Script cannot be initialized on this device or environment.'
        );
      }
    };
    loadScript();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (window.Client) {
      if (loaded === false) {
        document.getElementById('mining_status').innerHTML =
          'An error has occured. Please reload page.';
        Swal.fire({
          title: 'Error!',
          text: `Could not start mining!`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  }, [loaded]);
  return (
    <div className="mt-3 h-full w-full rounded-[20px]">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <div className="flex h-full w-full flex-col items-center rounded-[20px]">
            <div
              style={{ backgroundImage: `url(${NftBanner2})` }}
              className="relative flex h-[150px] w-full items-center justify-center rounded-[20px] bg-cover md:h-[120px] lg:h-[170px] 3xl:h-[220px]"
            >
              <div className="absolute -bottom-20 flex h-[180px] w-[180px] items-center justify-center rounded-full border-[10px] border-white bg-navy-700 dark:!border-navy-900">
                <img
                  className="h-full w-full rounded-full"
                  src={NftProfile}
                  alt=""
                />
              </div>
            </div>
            {/* profile info */}
            <div className="mt-[85px] flex flex-col items-center">
              <h1 className="mt-2 text-[55px] font-bold text-navy-700 dark:text-white">
                AFK Page
              </h1>
              <p
                id="mining_status"
                className="mt-[10px] flex w-full items-center justify-center text-lg font-medium text-gray-600"
              >
                Starting Mining Client...
              </p>
            </div>
            {/* eth owned */}
            <Card
              extra={
                'grid grid-cols-1 dark:shadow-none md:grid-cols-1 w-full md:w-[800px] h-full md:h-[160px] gap-2 py-4 mt-9'
              }
            >
              <div className="flex flex-col items-center justify-center border-gray-200 py-3 dark:border-white/10 md:border-r-2 md:py-0">
                <h3 className="flex items-center text-4xl font-bold text-navy-700 dark:text-white">
                  <p className="text-3xl">
                    <FaCoins />
                  </p>
                  {' ' + coins}
                </h3>
                <p className="text-base text-gray-600">
                  Total Coins Earned in this Session
                </p>
                <button
                  onClick={handleClick}
                  className="rounded-xl bg-brand-500 p-3 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Exit The AFK Page
                </button>
              </div>
            </Card>
            <div className="flex h-full w-full items-center justify-center md:w-[760px] lg:w-[870px]">
              <p className="mt-10 w-[280px] text-center font-dm text-lg leading-8 text-navy-700 dark:text-white md:w-full md:text-lg">
                AFK for Coins, {earning.afk_page.afk_coins} Coin per Minute.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AFKPage;
