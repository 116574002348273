import Card from 'components/card';
import { MdLeaderboard } from 'react-icons/md';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useMemo, useState, useEffect } from 'react';
import api from '../../../api.js';
import { Spinner } from '@chakra-ui/spinner';

const J4CTable = (props) => {
  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 9999;
  const [loading, setLoading] = useState(true);
  const [host, setHost] = useState({});
  useEffect(() => {
    api
      .get(`/settings/brand/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setHost(response.data.settings.url_base.replace(/(^\w+:|^)\/\//, ''));
        }
      })
      .catch((error) => {
        console.error('Error getting Branding Information: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card extra={'w-full h-full p-4 sm:overflow-x-auto'}>
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <div className="relative flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="flex h-14 w-14 items-center justify-center rounded-full bg-lightPrimary text-4xl text-brand-500 dark:!bg-navy-700 dark:text-white">
                <MdLeaderboard className="y-6 w-6" />
              </div>
              <div>
                <h5 className="text-sm font-medium leading-5 text-gray-500 dark:text-gray-400">
                  Join For Coins [Join The Discord Servers Below to Earn Extra
                  Coins, and click Claim Coins to claim all of the coins you
                  earned from J4C]
                </h5>
                <a
                  href={`https://discord.com/oauth2/authorize?client_id=1276913278320382075&response_type=code&redirect_uri=https%3A%2F%2F${host}%2Fearn%2Fj4c&scope=guilds+email+guilds.join+identify`}
                >
                  <button className="rounded-xl bg-gradient-to-br from-[#0083FE] to-[#00FFF0] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#0083FE]/50">
                    Claim All Coins
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
            <table {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={index}
                        className="border-b border-gray-200 pb-[10px] pr-28 dark:!border-navy-700"
                      >
                        <p className="text-start text-xs tracking-wide text-gray-600">
                          {column.render('Header')}
                        </p>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        let data = '';
                        if (cell.column.Header === 'NAME') {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        } else if (cell.column.Header === 'COINS') {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        } else if (cell.column.Header === 'ACTIONS') {
                          data = (
                            <div className="flex flex-row flex-wrap items-center gap-3">
                              <a
                                href={cell.row.original.invite}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <button className="rounded-xl bg-gradient-to-br from-[#0083FE] to-[#00FFF0] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#0083FE]/50">
                                  Join Discord
                                </button>
                              </a>
                            </div>
                          );
                        }
                        return (
                          <td
                            className="pb-[18px] pt-[14px] sm:text-[14px]"
                            {...cell.getCellProps()}
                            key={index}
                          >
                            {data}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </Card>
  );
};

export default J4CTable;
