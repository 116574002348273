import api from '../../../../api';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { Spinner } from '@chakra-ui/spinner';

const LinkedActionButtons = (props) => {
  const { id, value, name, py, border, actionName, action } = props;
  const [loading, setLoading] = useState(true);
  const [host, setHost] = useState({});
  useEffect(() => {
    api
      .get(`/settings/brand/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setHost(response.data.settings.url_base.replace(/(^\w+:|^)\/\//, ''));
        }
      })
      .catch((error) => {
        console.error('Error getting Branding Information: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unlink = (e) => {
    e.preventDefault();
    api
      .delete(`/account/discord/unlink/${id}`)
      .then(({ data }) => {
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `Two Factor Authentication have been disabled!`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Error disabling 2FA! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Error disabling 2FA!`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className={`flex flex-col items-center justify-between dark:!border-white/10 md:flex-row ${py} ${border} `}
    >
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <p className="text-base font-bold text-navy-700 dark:text-white md:mb-0">
            {' '}
            {name}{' '}
          </p>
          <div className="flex flex-col items-center justify-center md:flex-row">
            <p className="mb-3 text-base font-medium text-gray-600 md:!mb-0 md:mr-7">
              {' '}
              {value}{' '}
            </p>
            {action === 'link' ? (
              <a href={`https://discord.com/oauth2/authorize?client_id=1276913278320382075&response_type=code&redirect_uri=https%3A%2F%2F${host}%2Faccount%2Fsettings&scope=identify+guilds+guilds.join+email`}>
                <button className="linear flex items-center justify-center rounded-md border px-5 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-lightPrimary active:bg-gray-200 dark:!border-none dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  {actionName}
                </button>
              </a>
            ) : (
              <form onSubmit={unlink}>
                <button
                  type="submit"
                  className="linear flex items-center justify-center rounded-md border px-5 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-lightPrimary active:bg-gray-200 dark:!border-none dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  {actionName}
                </button>
              </form>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LinkedActionButtons;
