import Card from 'components/card';
import InputField from 'components/fields/InputField';
import api from '../../../api.js';
import { useAdminAuth } from 'contexts/AuthContextAdmin';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import { useState } from 'react';

const CreateJ4C = () => {
  useAdminAuth();
  const [buttonloading, setButtonLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const name = form.get('name');
    const invite = form.get('invite');
    const guildID = form.get('guildID');
    const coins = form.get('coins');
    api
      .post('/admin/j4c/create', {
        name,
        invite,
        guildID,
        coins,
      })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `J4C Server has been created, you will now be redirected to the J4C Server dashboard.`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/admin/j4c';
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Error while creating the J4C Server: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Error while creating the J4C Server, please try again.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      });
  };
  return (
    <div className="mt-3 h-full w-full">
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <Card extra={'h-full mx-auto pb-3 mt-10'}>
          <form onSubmit={handleSubmit}>
            <div className="rounded-[20px]">
              <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
                {/* Header */}
                <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                  J4C Server Info
                </h4>
                <div className="mb-3 flex w-full items-center justify-center gap-4">
                  <div className="w-1/2">
                    <InputField
                      field_name="name"
                      extra="mb-3"
                      label="Discord Server Name"
                      placeholder="Example Server"
                      id="name"
                      type="text"
                      required
                    />
                  </div>

                  <div className="w-1/2">
                    <InputField
                      field_name="invite"
                      extra="mb-3"
                      label="Discord Server Invite Link"
                      placeholder="https://discord.gg/xxxxxxxx"
                      id="invite"
                      type="url"
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 flex w-full items-center justify-center gap-4">
                  <div className="w-1/2">
                    <InputField
                      field_name="guildID"
                      extra="mb-3"
                      label="Discord Server ID"
                      placeholder="0000000000000000000"
                      id="guildID"
                      type="number"
                      minlength="19"
                      maxlength="19"
                      required
                    />
                  </div>

                  <div className="w-1/2">
                    <InputField
                      field_name="coins"
                      extra="mb-3"
                      label="Coins"
                      placeholder="100"
                      id="coins"
                      type="number"
                      min="1"
                      max="500"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 flex flex-col justify-between px-3 pt-2 md:flex-row md:px-8">
              {buttonloading ? (
                <button
                  className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                  disabled
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2">
                  Create J4C Server
                </button>
              )}
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default CreateJ4C;
