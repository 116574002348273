import { useState } from 'react';
import api from '../../../api.js';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import InputField from 'components/fields/InputField';

const RedeemForm = (props) => {
  const { name } = props;
  const auth = useAuth();
  const id = auth.user.id;
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const code = form.get('code');
    api
      .post('/coupon/redeem', { id, code })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `You have sucessfully redeemed the coupon for ${data.coins} Coins.`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Redeeming Coupon was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Redeeming Coupon was not successful! Please contact your administrator.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="flex h-full w-full flex-col items-center rounded-[20px] px-[1px] pt-[3px] dark:bg-navy-900 sm:px-[150px] lg:px-[0px] xl:px-[35px]">
      <h1 className="mx-auto mt-14 text-[35px] font-bold text-navy-700 dark:text-white md:px-[10px] lg:text-[30px] xl:text-[53px]">
        {name}
      </h1>
      <form
        onSubmit={handleSubmit}
        className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
      >
        <p className="mb-9 ml-1 text-base text-gray-600">
          Redeem Coupon Codes from Events or the server discord here for coins.
        </p>
        {/* Email */}
        <InputField
          field_name="code"
          extra="mb-3"
          label={'Coupon Code'}
          placeholder="CODE"
          id="code"
          type="text"
          required
        />
        {buttonLoading ? (
          <button
            className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            disabled
          >
            <Spinner
              size="xl"
              thickness="2px"
              style={{ height: 14, width: 14 }}
            />
          </button>
        ) : (
          <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Redeem Coupon
          </button>
        )}
      </form>
    </div>
  );
};

export default RedeemForm;
