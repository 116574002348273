import React, { useState, useEffect } from 'react';
import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import Checkbox from 'components/checkbox';
import api from '../../api.js';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import { FaDiscord } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';

function Login() {
  const auth = useAuth();
  const location = useLocation();
  const [sbuttonloading, setSbuttonLoading] = React.useState(false);
  const [dbuttonloading, setDbuttonLoading] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [host, setHost] = useState({});
  useEffect(() => {
    api
      .get(`/settings/brand/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setHost(response.data.settings.url_base.replace(/(^\w+:|^)\/\//, ''));
        }
      })
      .catch((error) => {
        console.error('Error getting Branding Information: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (code) {
      setLoading(true);
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((info) => {
          const ip = info.ip;
          return api.post('/auth/discord', { code, ip });
        })
        .then(({ data }) => {
          if (data.status === 'success') {
            setDbuttonLoading(false);
            auth.login(data, false);
          } else {
            setDbuttonLoading(false);
            Swal.fire({
              title: 'Error!',
              text: `Login was not successful! Error: ${data.message}`,
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        })
        .catch(() => {
          return api
            .post('/auth/discord', { code, ip: 'Error' })
            .then(({ data }) => {
              setDbuttonLoading(false);
              if (data.status === 'success') {
                auth.login(data, false);
              } else {
                Swal.fire({
                  title: 'Error!',
                  text: `Login was not successful! Error: ${data.message}`,
                  icon: 'error',
                  confirmButtonText: 'OK',
                });
              }
            })
            .catch((error) => {
              setDbuttonLoading(false);
              if (error instanceof SyntaxError) {
                Swal.fire({
                  icon: 'error',
                  title: 'Error!',
                  text: 'Network Error',
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error!',
                  text: 'Disable your aggressive ad-blocker/firewall and refresh',
                });
              }
            });
        });
    }
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const vcode = searchParams.get('vcode');
    if (vcode) {
      api.post('/auth/email/verify', { code: vcode }).then(({ data }) => {
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `Your email is now verified! Please proceed to login.`,
            icon: 'success',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Email Verification token is either invalid or expired, please request a new one.`,
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/auth/login';
          });
        }
      });
    }
  }, [location.search]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSbuttonLoading(true);

    let ip = 'Error'; // Default IP value if fetching fails

    try {
      const response = await fetch(`https://api.ipify.org?format=json`);
      const info = await response.json();
      ip = info.ip; // Update IP if fetch is successful
    } catch (error) {
      console.log('Disable your adblocker/firewall.');
    }

    const form = new FormData(e.target);
    const email = form.get('email');
    const password = form.get('password');

    api
      .post('/auth/login', { email, password, isChecked, ip })
      .then(({ data }) => {
        setSbuttonLoading(false);
        if (data.status === 'success') {
          if (data.user.email_verified) {
            auth.login(data, isChecked);
          } else {
            Swal.fire({
              title: 'Error!',
              text: `Please verify your email first.`,
              icon: 'error',
              showDenyButton: true,
              confirmButtonText: 'Ok',
              denyButtonText: `Send new verification email`,
            }).then((result) => {
              if (result.isDenied) {
                api.post('/auth/email/resend', { email }).then(({ data }) => {
                  if (data.status === 'success') {
                    Swal.fire({
                      title: 'Success!',
                      text: `New email verification link sent! Please check your inbox.`,
                      icon: 'success',
                      confirmButtonText: 'OK',
                    });
                  } else {
                    Swal.fire({
                      title: 'Error!',
                      text: `Error while sending email! Error: ${data.message}`,
                      icon: 'error',
                      confirmButtonText: 'OK',
                    });
                  }
                });
              }
            });
          }
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Login was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Login was not successful! Please contact your administrator.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        setSbuttonLoading(false);
        console.log(err);
      });
  };

  return (
    <Default
      maincard={
        <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            {loading ? (
              <div className="flex h-32 items-center justify-center">
                <Spinner
                  size="xl"
                  thickness="6px"
                  style={{ height: 100, width: 100 }}
                />
              </div>
            ) : (
              <>
                <form
                  onSubmit={handleSubmit}
                  className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
                >
                  <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Sign In
                  </h3>
                  {dbuttonloading ? (
                    <div className="mt-9 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:!bg-navy-800">
                      <div className="rounded-full text-xl">
                        <FaDiscord style={{ color: 'white' }} />
                      </div>
                      <p className="text-sm font-medium text-navy-700 dark:text-white">
                        <Spinner
                          size="xl"
                          thickness="2px"
                          style={{ height: 14, width: 14 }}
                        />
                      </p>
                    </div>
                  ) : (
                    <a
                      href={`https://discord.com/oauth2/authorize?client_id=1276913278320382075&response_type=code&redirect_uri=https%3A%2F%2F${host}%2Fauth%2Flogin&scope=identify+guilds+guilds.join+email`}
                    >
                      <div className="mt-9 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:!bg-navy-800">
                        <div className="rounded-full text-xl">
                          <FaDiscord style={{ color: 'white' }} />
                        </div>
                        <p className="text-sm font-medium text-navy-700 dark:text-white">
                          Login with Discord
                        </p>
                      </div>
                    </a>
                  )}
                  <div className="mb-4 mt-6 flex items-center gap-3">
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                    <p className="text-base font-medium text-gray-600"> or </p>
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                  </div>
                  <p className="mb-9 ml-1 text-base text-gray-600">
                    Enter your email and password to sign in!
                  </p>
                  {/* Email */}
                  <InputField
                    field_name="email"
                    variant="auth"
                    extra="mb-3"
                    label="Email / Username*"
                    placeholder="example@gmail.com / example"
                    id="email"
                    type="text"
                  />

                  {/* Password */}
                  <InputField
                    field_name="password"
                    variant="auth"
                    extra="mb-3"
                    label="Password*"
                    placeholder="*********"
                    id="password"
                    type="password"
                  />
                  {/* Checkbox */}
                  <div className="mb-4 flex items-center justify-between px-2">
                    <div className="mt-2 flex items-center">
                      <Checkbox
                        id="extend_login"
                        field_name="extend_login"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                        Keep me logged In
                      </p>
                    </div>
                    <a
                      className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                      href="/auth/password/reset"
                    >
                      Forgot Password?
                    </a>
                  </div>
                  {sbuttonloading ? (
                    <button
                      className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      disabled
                    >
                      <Spinner
                        size="xl"
                        thickness="2px"
                        style={{ height: 14, width: 14 }}
                      />
                    </button>
                  ) : (
                    <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                      Login
                    </button>
                  )}
                </form>
              </>
            )}
            <div className="mt-4">
              <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                No Account?
              </span>
              <a
                href="/auth/signup"
                className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default Login;
