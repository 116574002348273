import api from '../../api.js';
import { Spinner } from '@chakra-ui/spinner';
import { useState, useEffect } from 'react';

const Footer = () => {
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState({});
  useEffect(() => {
    api
      .get(`/settings/brand/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setBrand(response.data.settings);
        }
      })
      .catch((error) => {
        console.error('Error getting Branding Information: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <p className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
            <span className="mb-4 text-center text-sm text-gray-600 sm:!mb-0 md:text-base">
              ©{new Date().getFullYear()} {brand.company_name}. All Rights
              Reserved.
            </span>
          </p>
          <div>
            <ul className="flex flex-wrap items-center gap-3 sm:flex-nowrap md:gap-10">
              <li>
                <a
                  target="blank"
                  href={brand.links.support}
                  className="text-base font-medium text-gray-600 hover:text-gray-600"
                >
                  Support
                </a>
              </li>
              <li>
                <a
                  target="blank"
                  href={brand.links.tos}
                  className="text-base font-medium text-gray-600 hover:text-gray-600"
                >
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
