import { MdHome, MdAdd, MdLeaderboard } from 'react-icons/md';
import { FaCog, FaCoins, FaLink, FaServer, FaStore } from 'react-icons/fa';
import { FaTicket } from 'react-icons/fa6';

// Admin Imports
import Dashboard from 'views/dashboard';
import Signup from 'views/auth/SignUp.jsx';
import Login from 'views/auth/Login.jsx';
import NotFound from 'views/404';
import ResetPassword from 'views/auth/ResetPassword.jsx';
import Reset2FA from 'views/auth/Reset2FA.jsx';
import SetPassword from 'views/auth/SetPassword.jsx';
import TwoFactor from 'views/auth/2FA.jsx';
import Settings from 'views/account/settings';
import CreateServer from 'views/server/create.jsx';
import UpdateServer from 'views/server/update.jsx';
import Store from 'views/store';
import RedeemCoupon from 'views/earning/RedeemCoupon.jsx';
import LinkVertise from 'views/earning/LinkVertise.jsx';
import AFKPage from 'views/earning/AFKPage.jsx';
import JoinForCoins from 'views/earning/J4C.jsx';
import CheckoutCoins from 'views/buy/Coins.jsx';
import CheckoutServerSlots from 'views/buy/ServerSlots.jsx';

import Leaderboard from 'views/earning/Leaderboard.jsx';

import AdminLogin from 'views/admin/auth/Login.jsx';
import AdminDashboard from 'views/admin/dashboard';
import UpdateUser from 'views/admin/user/update';
import Coupons from 'views/admin/coupons';
import UpdateCoupons from 'views/admin/coupons/update';
import CreateCoupon from 'views/admin/coupons/create';
import J4C from 'views/admin/j4c';
import UpdateJ4C from 'views/admin/j4c/update';
import CreateJ4C from 'views/admin/j4c/create';
import Servers from 'views/admin/servers';

const routes = [
  {
    name: 'Dashboard',
    layout: '/dashboard',
    path: 'dashboard',
    icon: <MdHome className="h-6 w-6" />,
    component: <Dashboard />,
  },
  {
    name: 'Create Server',
    layout: '/dashboard',
    path: 'server/create',
    icon: <MdAdd className="h-6 w-6" />,
    component: <CreateServer />,
  },
  {
    name: 'Store',
    layout: '/dashboard',
    path: 'store',
    icon: <FaStore className="h-6 w-6" />,
    component: <Store />,
  },
  {
    name: 'Earning',
    path: '/earning',
    icon: <FaCoins className="h-6 w-6" />,
    collapse: true,
    items: [
      {
        name: 'Redeem Coupon',
        layout: '/dashboard',
        path: 'coupon/redeem',
        icon: <FaTicket className="h-6 w-6" />,
        component: <RedeemCoupon />,
      },
      {
        name: 'AFK Earning Page',
        layout: '/dashboard',
        path: 'earn/afk',
        icon: <FaCoins className="h-6 w-6" />,
        component: <AFKPage />,
      },
      {
        name: 'LinkVertise',
        layout: '/dashboard',
        path: 'earn/lv',
        icon: <FaLink className="h-6 w-6" />,
        component: <LinkVertise />,
      },
      {
        name: 'Join for Coins',
        layout: '/dashboard',
        path: 'earn/j4c',
        icon: <FaCoins className="h-6 w-6" />,
        component: <JoinForCoins />,
      },
      {
        name: 'Earning Leaderboard',
        layout: '/dashboard',
        path: 'earn/leaderboard',
        icon: <MdLeaderboard className="h-6 w-6" />,
        component: <Leaderboard />,
      },
    ],
  },
  {
    name: 'Buy Coins',
    layout: '/dashboard',
    path: 'coins/buy',
    icon: <FaStore className="h-6 w-6" />,
    component: <CheckoutCoins />,
  },
  {
    name: 'Buy Server Slots',
    layout: '/dashboard',
    path: 'server-slots/buy',
    icon: <FaStore className="h-6 w-6" />,
    component: <CheckoutServerSlots />,
  },
  {
    name: 'Settings',
    layout: '/dashboard',
    path: 'account/settings',
    icon: <FaCog className="h-6 w-6" />,
    component: <Settings />,
  },
  {
    name: 'Login',
    layout: '/auth',
    path: 'login',
    component: <Login />,
    sidebarLink: false,
  },
  {
    name: 'Reset Password',
    layout: '/auth',
    path: 'password/reset',
    component: <ResetPassword />,
    sidebarLink: false,
  },
  {
    name: 'Set Password',
    layout: '/auth',
    path: 'password/set',
    component: <SetPassword />,
    sidebarLink: false,
  },
  {
    name: 'Reset 2FA',
    layout: '/auth',
    path: '2fa/reset',
    component: <Reset2FA />,
    sidebarLink: false,
  },
  {
    name: 'Two Factor Verification',
    layout: '/auth',
    path: '2fa',
    component: <TwoFactor />,
    sidebarLink: false,
  },
  {
    name: 'Signup',
    layout: '/auth',
    path: 'signup',
    component: <Signup />,
    sidebarLink: false,
  },
  {
    name: 'Update Server',
    layout: '/dashboard',
    path: 'server/update',
    component: <UpdateServer />,
    sidebarLink: false,
  },
  {
    name: 'Admin Login',
    layout: '/auth',
    path: 'admin/login',
    component: <AdminLogin />,
    sidebarLink: false,
  },
  {
    name: 'Dashboard',
    layout: '/admin',
    path: 'dashboard',
    icon: <MdHome className="h-6 w-6" />,
    component: <AdminDashboard />,
  },
  {
    name: 'Update User Details',
    layout: '/admin',
    path: 'user/update',
    component: <UpdateUser />,
    sidebarLink: false,
  },
  {
    name: 'Coupons',
    layout: '/admin',
    path: 'coupons',
    icon: <FaTicket className="h-6 w-6" />,
    component: <Coupons />,
  },
  {
    name: 'Create Coupon',
    layout: '/admin',
    path: 'coupon/create',
    component: <CreateCoupon />,
    sidebarLink: false,
  },
  {
    name: 'Update Coupon Details',
    layout: '/admin',
    path: 'coupon/update',
    component: <UpdateCoupons />,
    sidebarLink: false,
  },
  {
    name: 'J4C Server',
    layout: '/admin',
    path: 'j4c',
    icon: <FaServer className="h-6 w-6" />,
    component: <J4C />,
  },
  {
    name: 'Create J4C Server',
    layout: '/admin',
    path: 'j4c/create',
    component: <CreateJ4C />,
    sidebarLink: false,
  },
  {
    name: 'Update J4C Server Details',
    layout: '/admin',
    path: 'j4c/update',
    component: <UpdateJ4C />,
    sidebarLink: false,
  },
  {
    name: 'Servers',
    layout: '/admin',
    path: 'servers',
    icon: <FaServer className="h-6 w-6" />,
    component: <Servers />,
  },
  {
    name: '404',
    layout: '/dashboard',
    path: '404',
    component: <NotFound />,
    sidebarLink: false,
  },
];

export default routes;
