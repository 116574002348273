import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import api from '../api.js';
const AuthContextAdmin = React.createContext({});

export const useAdminAuth = () => React.useContext(AuthContextAdmin);

export const AuthProviderAdmin = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({});
  const [two_factor, setTwo_factor] = useState(false);

  useEffect(() => {
    setLoading(true);

    const userSessionCookie = Cookies.get('AdminUser');

    if (userSessionCookie) {
      const { sessionToken } = JSON.parse(userSessionCookie);

      api
        .post('/auth/admin/validate', { sessionToken })
        .then((response) => {
          if (response.data.status === 'success') {
            setToken(sessionToken);
          } else {
            Cookies.remove('AdminUser');
          }
        })
        .catch((error) => {
          console.error('Token validation error: ', error);
          Cookies.remove('AdminUser');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    setLoading2(true);

    if (userSessionCookie) {
      const { sessionToken, session_two_factor } =
        JSON.parse(userSessionCookie);

      api
        .post('/auth/admin/user/live', { sessionToken })
        .then((response) => {
          if (response.data.status === 'success') {
            setUser(response.data.user);
            if (session_two_factor === true) {
              setTwo_factor(true);
            }
          } else {
            Cookies.remove('AdminUser');
          }
        })
        .catch((error) => {
          console.error('Token validation error: ', error);
          Cookies.remove('AdminUser');
        })
        .finally(() => {
          setLoading2(false);
        });
    } else {
      setLoading2(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback((data) => {
    let sessionToken = data.sessionToken;
    let user = data.user;

    // Verify and decode the JWT before setting it
    try {
      setToken(sessionToken);
      let expirationTime = new Date();
      expirationTime.setTime(expirationTime.getTime() + 1000 * 60 * 60);
      if (JSON.parse(user.two_factor).google_auth) {
        setTwo_factor(false);
        Cookies.set(
          'AdminUser',
          JSON.stringify({ sessionToken, session_two_factor: false }),
          {
            expires: expirationTime,
          }
        );
      } else {
        setTwo_factor(true);
        Cookies.set(
          'AdminUser',
          JSON.stringify({ sessionToken, session_two_factor: true }),
          {
            expires: expirationTime,
          }
        );
      }
      setUser(user);
    } catch (error) {
      console.error('JWT verification failed:', error);
      setToken(null);
      setUser(null);
      Cookies.remove('AdminUser');
    }
  }, []);

  const two_factor_auth = useCallback((data) => {
    const userSessionCookie = Cookies.get('AdminUser');
    const current_data = JSON.parse(userSessionCookie);
    setTwo_factor(true);
    let expirationTime = new Date();
    expirationTime.setTime(expirationTime.getTime() + 1000 * 60 * 60);
    const sessionToken = current_data.sessionToken;
    Cookies.set(
      'User',
      JSON.stringify({ sessionToken, session_two_factor: true }),
      {
        expires: expirationTime,
      }
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUser(null);
    Cookies.remove('AdminUser');
  }, []);

  return (
    <AuthContextAdmin.Provider
      value={{
        loading,
        loading2,
        token,
        user,
        login,
        logout,
        two_factor,
        two_factor_auth,
      }}
    >
      {children}
    </AuthContextAdmin.Provider>
  );
};
