const Banner = (props) => {
  const { image } = props;
  return (
    <div
      style={{ backgroundImage: `url(${image})` }}
      className="relative h-[400px] w-full rounded-[20px] bg-cover bg-no-repeat lg:h-[930px]"
    ></div>
  );
};

export default Banner;
