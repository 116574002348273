import SearchIcon from 'components/icons/SearchIcon';
import React, { useMemo, useState, useEffect } from 'react';
import {
  MdChevronRight,
  MdChevronLeft,
  MdCheckCircle,
  MdOutlineError,
} from 'react-icons/md';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import api from '../../../api';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import { useAuth } from 'contexts/AuthContext';
import { FaServer } from 'react-icons/fa';
import Card from 'components/card';

function ServerTable(props) {
  const auth = useAuth();
  const user = auth.user;
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [buttonloading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [upanelURL, setUPanelURL] = useState(null);
  useEffect(() => {
    api
      .get(`/settings/brand/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setUPanelURL(response.data.settings.panel_url);
        }
      })
      .catch((error) => {
        console.error('Error getting Branding Information: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,
    setPageSize,

    setGlobalFilter,
    state,
  } = tableInstance;

  const { pageSize } = state;

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const panelURL = (identifier) => {
    window.open(`${upanelURL}/server/${identifier}`, '_blank');
  };

  const editServer = (id) => {
    window.location.replace(`/server/update?server_id=${id}`);
  };

  const deleteServer = (server_id) => {
    setButtonLoading(true);
    const id = user.id;
    api
      .post('/server/delete', { id, server_id })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `Server Deleted!`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Deleting Server was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Deleting Server was not successful! Please contact support.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card extra={'w-full h-full p-4 sm:overflow-x-auto'}>
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          {/* Search */}
          <div
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="flex w-[400px] max-w-full items-center rounded-md p-[8px] pt-[20px] xl:ml-3"
          >
            <div className="flex h-[38px] w-[400px] flex-grow items-center rounded-md bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white">
              <div className="flex items-center gap-3">
                <div className="flex h-14 w-14 items-center justify-center rounded-full bg-lightPrimary text-4xl text-brand-500 dark:!bg-navy-700 dark:text-white">
                  <FaServer className="y-6 w-6" />
                </div>
                <div>
                  <h5 className="text-sm font-medium leading-5 text-gray-500 dark:text-gray-400">
                    Servers
                  </h5>
                  <p className="mt-1 text-2xl font-bold leading-6 text-navy-900 dark:text-white">
                    {tableData.length}
                  </p>
                </div>
              </div>
              <SearchIcon />
              <input
                type="text"
                placeholder="Search...."
                className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
              />
            </div>
          </div>

          {/* table */}
          <div className="mt-[14px] h-full w-full overflow-x-scroll 2xl:overflow-x-hidden">
            <table {...getTableProps()} className="w-full font-dm">
              <thead className="w-full">
                {headerGroups.map((headerGroup, index) => (
                  <tr
                    className="items-center border-b border-gray-200 dark:!border-white/10"
                    {...headerGroup.getHeaderGroupProps()}
                    key={index}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="px-[27px] pb-[12px] pt-[32px] text-start text-xs tracking-wide text-gray-600"
                        key={index}
                      >
                        <div className="text-xs font-bold">
                          {column.render('Header')}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      className="border-b border-gray-200 dark:!border-white/10"
                      {...row.getRowProps()}
                      key={index}
                    >
                      {row.cells.map((cell, index) => {
                        let data = '';
                        if (cell.column.Header === 'ID') {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        }
                        if (cell.column.Header === 'Server Identifier') {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        } else if (cell.column.Header === 'Cost') {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        } else if (cell.column.Header === 'Next Billing Date') {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {formatDate(cell.value)}
                            </p>
                          );
                        } else if (cell.column.Header === 'Status') {
                          data = (
                            <div className="flex items-center gap-2">
                              {cell.value === 0 ? (
                                <>
                                  <div className={`rounded-full text-xl`}>
                                    <MdCheckCircle className="text-green-500" />
                                  </div>
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    Active
                                  </p>
                                </>
                              ) : cell.value === 1 ? (
                                <>
                                  <div className={`rounded-full text-xl`}>
                                    <MdOutlineError className="text-orange-500" />
                                  </div>
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    Suspended
                                  </p>
                                </>
                              ) : null}
                            </div>
                          );
                        } else if (
                          cell.column.Header === 'Actions' &&
                          buttonloading === false
                        ) {
                          data = (
                            <div className="flex flex-row flex-wrap items-center gap-3">
                              <button
                                onClick={() =>
                                  panelURL(cell.row.original.server_identifier)
                                }
                                className="rounded-xl bg-gradient-to-br from-[#0083FE] to-[#00FFF0] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#0083FE]/50"
                              >
                                Panel
                              </button>
                              <button
                                onClick={() =>
                                  editServer(cell.row.original.server_id)
                                }
                                className="rounded-xl bg-gradient-to-br from-[#0083FE] to-[#00FFF0] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#0083FE]/50"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() =>
                                  deleteServer(cell.row.original.server_id)
                                }
                                className="rounded-xl bg-gradient-to-br from-[#FF416C] to-[#FF4B2B] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FF416C]/50"
                              >
                                Delete
                              </button>
                            </div>
                          );
                        } else if (
                          cell.column.Header === 'Actions' &&
                          buttonloading === true
                        ) {
                          data = (
                            <div className="flex flex-row flex-wrap items-center gap-3">
                              <button
                                onClick={() =>
                                  editServer(cell.row.original.server_id)
                                }
                                className="rounded-xl bg-gradient-to-br from-[#0083FE] to-[#00FFF0] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#0083FE]/50"
                                disabled="true"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() =>
                                  deleteServer(cell.row.original.server_id)
                                }
                                className="rounded-xl bg-gradient-to-br from-[#FF416C] to-[#FF4B2B] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FF416C]/50"
                                disabled="true"
                              >
                                <Spinner
                                  size="xl"
                                  thickness="2px"
                                  style={{ height: 14, width: 14 }}
                                />
                              </button>
                            </div>
                          );
                        }
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className="mt-[20px] px-4 py-[16px]"
                          >
                            {data}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* pagination */}
          <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
            {/* left side */}
            <div className="flex items-center gap-3">
              <p className="> Show rows per page text-sm text-gray-700">
                Show rows per page{' '}
              </p>
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="h-[32px] w-[75px] rounded-[20px] border border-gray-200 px-2 text-gray-700 dark:!border-white/10 dark:bg-navy-800"
                name=""
                id=""
              >
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
            {/* right side */}
            <div className="flex items-center gap-2">
              <button
                onClick={() => previousPage()}
                className={`linear flex items-center justify-center rounded-md bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
              >
                <MdChevronLeft />
              </button>

              <button
                onClick={() => nextPage()}
                className={`linear flex items-center justify-center rounded-md bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
              >
                <MdChevronRight />
              </button>
            </div>
          </div>
        </>
      )}
    </Card>
  );
}

export default ServerTable;
