import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth } from 'contexts/AuthContext';
import Table from './components/Table';
import Widget from './components/Widget';
import {
  FaServer,
  FaCoins,
  FaCircle,
  FaBoxes,
  FaMemory,
  FaDatabase,
  FaEthernet,
} from 'react-icons/fa';
import { FaMicrochip } from 'react-icons/fa6';
import { BsDeviceHddFill } from 'react-icons/bs';
import banner from 'assets/img/profile/banner.png';
import Card from 'components/card';
import md5 from 'blueimp-md5';
import { MdBackup } from 'react-icons/md';
import { Spinner } from '@chakra-ui/spinner';
import api from '../../api.js';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const Dashboard = () => {
  const auth = useAuth();
  const location = useLocation();
  const user = auth.user;
  const id = user.id;
  const storedDatetime = user.last_login;
  const storedDate = new Date(storedDatetime);
  const currentDate = new Date();
  const timeDifference = currentDate - storedDate;
  const minutesDifference = Math.round(Math.abs(timeDifference) / 60000);
  const resources_data = useMemo(
    () => JSON.parse(user.resources_data),
    [user.resources_data]
  );
  const [loading, setLoading] = useState(true);
  const [servers, setServers] = useState([]);
  const columns = [
    {
      Header: 'ID',
      accessor: 'server_id',
    },
    {
      Header: 'Server Identifier',
      accessor: 'server_identifier',
    },
    {
      Header: 'Cost',
      accessor: 'cost',
    },
    {
      Header: 'Next Billing Date',
      accessor: 'next_billing_date',
    },
    {
      Header: 'Status',
      accessor: 'suspended',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
  ];
  const hash = useCallback((email) => {
    const lowerCaseEmail = email.toLowerCase().trim();
    return md5(lowerCaseEmail);
  }, []);
  const get_pfp = useCallback(
    (email) => {
      return `https://www.gravatar.com/avatar/${hash(email)}`;
    },
    [hash]
  );
  useEffect(() => {
    api
      .get(`/servers/get/${user.id}`)
      .then((response) => {
        if (response.data.status === 'success') {
          setServers(response.data.servers);
        }
      })
      .catch((error) => {
        console.error('Error getting Servers: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const orderID = searchParams.get('token');
    if (orderID) {
      api.post('/checkout/paypal/capture', { id, orderID }).then(({ data }) => {
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `You have successfully purchased ${data.amount} ${data.type}.`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/dashboard';
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Completing purchase was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      });
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div className="h-full w-full rounded-md">
      {/* Reserved for future statistics */}
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-md xl:flex-row">
            {/* right section */}
            <div className="h-full w-full xl:w-[400px] xl:min-w-[300px] 2xl:min-w-[400px]">
              <Card extra={'items-center w-full h-full p-[16px] bg-cover'}>
                {/* Background and profile */}
                <div
                  className="relative mt-1 flex h-32 w-full justify-center rounded-md bg-cover"
                  style={{ backgroundImage: `url(${banner})` }}
                >
                  <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white dark:!border-navy-700">
                    <img
                      className="h-full w-full rounded-full"
                      src={get_pfp(auth.user.email)}
                      alt={
                        'Profile Picture of' +
                        auth.user.firstname +
                        ' ' +
                        auth.user.lastname
                      }
                    />
                  </div>
                </div>

                {/* Name and position */}
                <div className="mt-16 flex flex-col items-center">
                  <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    {auth.user.firstname + ' ' + auth.user.lastname}
                  </h4>
                  <h5 className="text-base font-normal text-gray-600">
                    Customer
                  </h5>
                </div>

                {/* Post followers */}
                <div className="mb-3 mt-6 flex gap-4 md:!gap-14">
                  <div className="flex flex-col items-center justify-center">
                    <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
                      <div className="mb-2 flex flex-row items-center rounded-lg bg-brand-500 px-3 py-2 text-xs font-bold uppercase text-white transition duration-200 dark:bg-brand-400">
                        <div className="flex h-5 w-5 items-center justify-center">
                          <FaCircle />
                        </div>
                        Online
                      </div>
                    </h4>
                    <p className="text-sm font-normal text-gray-600">Status</p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <h4 className="mb-2 text-2xl font-bold text-navy-700 dark:text-white">
                      {minutesDifference + ' Minutes'}
                    </h4>
                    <p className="text-sm font-normal text-gray-600">
                      Last Login
                    </p>
                  </div>
                </div>
              </Card>
            </div>
            {/* line */}
            <div className="flex w-0 bg-gray-200 dark:bg-[#ffffff33] xl:w-px" />
            <div className="h-full w-full rounded-md">
              <div className="mt-3">
                <div className="mb-5">
                  <Card extra={'p-6'}>
                    <div className="flex items-center gap-3">
                      <div className="flex h-14 w-14 items-center justify-center rounded-full bg-lightPrimary text-4xl text-brand-500 dark:!bg-navy-700 dark:text-white">
                        <FaBoxes className="y-6 w-6" />
                      </div>
                      <div>
                        <p className="mt-1 text-2xl font-bold leading-6 text-navy-900 dark:text-white">
                          Available Resources
                        </p>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
                <Widget
                  icon={<FaCoins className="h-7 w-7" />}
                  title={'Coins'}
                  subtitle={user.coins}
                />
                <Widget
                  icon={<FaServer className="h-7 w-7" />}
                  title={'Server Slots'}
                  subtitle={resources_data.server_slots}
                />
                <Widget
                  icon={<FaMicrochip className="h-6 w-6" />}
                  title={'CPU'}
                  subtitle={resources_data.cpu + '%'}
                />
                <Widget
                  icon={<FaMemory className="h-7 w-7" />}
                  title={'RAM'}
                  subtitle={resources_data.ram + ' MB'}
                />
              </div>
              <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
                <Widget
                  icon={<BsDeviceHddFill className="h-7 w-7" />}
                  title={'Disk'}
                  subtitle={
                    Math.round((resources_data.disk / 1024) * 10) / 10 + ' GB'
                  }
                />
                <Widget
                  icon={<FaDatabase className="h-7 w-7" />}
                  title={'Databases'}
                  subtitle={resources_data.dbs}
                />
                <Widget
                  icon={<FaEthernet className="h-6 w-6" />}
                  title={'Extra Ports/Allocations'}
                  subtitle={resources_data.extra_ports}
                />
                <Widget
                  icon={<MdBackup className="h-7 w-7" />}
                  title={'Backup Slots'}
                  subtitle={resources_data.backup_slots}
                />
              </div>
            </div>
          </div>
          <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-md xl:flex-row">
            <div className="h-full w-full rounded-md">
              <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                <div className="col-span-6 h-full w-full rounded-md 3xl:col-span-4">
                  <div className="h-full w-full rounded-[14px]  ">
                    <Table columnsData={columns} tableData={servers} />
                  </div>
                </div>
              </div>
              <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-1"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
