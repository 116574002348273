import Resource from './components/Resource.jsx';
import Card from 'components/card';
import { useAuth } from 'contexts/AuthContext';
import Widget from './components/Widget';
import {
  FaServer,
  FaCoins,
  FaBoxes,
  FaMemory,
  FaDatabase,
  FaEthernet,
} from 'react-icons/fa';
import { MdBackup } from 'react-icons/md';
import { FaMicrochip } from 'react-icons/fa6';
import { BsDeviceHddFill } from 'react-icons/bs';
import OrderForm from './components/OrderForm';
import api from '../../api.js';
import { Spinner } from '@chakra-ui/spinner';
import { useEffect, useState } from 'react';

const Store = () => {
  const auth = useAuth();
  const user = auth.user;
  const resources_data = JSON.parse(user.resources_data);
  const [loading, setLoading] = useState(true);
  const [pricing, setPricing] = useState({});
  useEffect(() => {
    api
      .get(`/settings/pricing/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setPricing(response.data.settings);
        }
      })
      .catch((error) => {
        console.error('Error getting Servers: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-3 h-full w-full">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <div className="mb-5 h-full w-full rounded-md">
            <div className="mt-3">
              <div className="mb-5">
                <Card extra={'p-6'}>
                  <div className="flex items-center gap-3">
                    <div className="flex h-14 w-14 items-center justify-center rounded-full bg-lightPrimary text-4xl text-brand-500 dark:!bg-navy-700 dark:text-white">
                      <FaBoxes className="y-6 w-6" />
                    </div>
                    <div>
                      <p className="mt-1 text-2xl font-bold leading-6 text-navy-900 dark:text-white">
                        Available Resources
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
              <Widget
                icon={<FaCoins className="h-7 w-7" />}
                title={'Coins'}
                subtitle={user.coins}
              />
              <Widget
                icon={<FaServer className="h-7 w-7" />}
                title={'Server Slots'}
                subtitle={resources_data.server_slots}
              />
              <Widget
                icon={<FaMicrochip className="h-6 w-6" />}
                title={'CPU'}
                subtitle={resources_data.cpu + '%'}
              />
              <Widget
                icon={<FaMemory className="h-7 w-7" />}
                title={'RAM'}
                subtitle={resources_data.ram + ' MB'}
              />
            </div>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
              <Widget
                icon={<BsDeviceHddFill className="h-7 w-7" />}
                title={'Disk'}
                subtitle={
                  Math.round((resources_data.disk / 1024) * 10) / 10 + ' GB'
                }
              />
              <Widget
                icon={<FaDatabase className="h-7 w-7" />}
                title={'Databases'}
                subtitle={resources_data.dbs}
              />
              <Widget
                icon={<FaEthernet className="h-6 w-6" />}
                title={'Extra Ports/Allocations'}
                subtitle={resources_data.extra_ports}
              />
              <Widget
                icon={<MdBackup className="h-7 w-7" />}
                title={'Backup Slots'}
                subtitle={resources_data.backup_slots}
              />
            </div>
          </div>
          <div className="mt-3 flex h-full w-full flex-col font-dm md:gap-7 lg:flex-row">
            <div className="h-full w-full rounded-[20px] lg:w-1/2">
              <Resource
                extra="mb-5"
                bgBox="bg-gradient-to-br from-[#7A64FF] via-[#FF508B] to-[#FD6D53]"
                icon={<FaMicrochip />}
                title="Buy CPU (%)"
                order={
                  <OrderForm
                    name="CPU"
                    api_url="/store/cpu"
                    suffix="% of CPU"
                    cost={pricing.cpu / 10}
                    desc={pricing.cpu + ' Coins Per 10%'}
                  />
                }
              />

              <Resource
                extra="mb-5"
                bgBox="bg-gradient-to-br from-[#92FE9D] to-[#00C9FF]"
                icon={<FaMemory />}
                title="Buy RAM [Server Memory] (MB)"
                order={
                  <OrderForm
                    name="RAM"
                    api_url="/store/ram"
                    buffer={1024}
                    suffix=" MB of RAM"
                    cost={pricing.ram / 1024}
                    desc={pricing.ram + ' Coins per 1024 MB'}
                  />
                }
              />
              <Resource
                bgBox="bg-gradient-to-br from-[#FF9966] to-[#FF5E62]"
                icon={<BsDeviceHddFill />}
                title="Buy Disk [Server Storage] (MB)"
                order={
                  <OrderForm
                    name="Disk"
                    api_url="/store/disk"
                    suffix=" MB of Disk"
                    cost={pricing.disk / 1024}
                    desc={pricing.disk + ' Coins per 1024 MB'}
                  />
                }
              />
            </div>
            <div className="h-0 w-0 bg-gray-300 dark:!bg-navy-700 lg:h-[1050px] lg:w-px" />
            <div className="h-full w-full rounded-[20px] lg:w-1/2">
              <Resource
                extra="mb-5"
                bgBox="bg-gradient-to-br from-[#7A64FF] via-[#FF508B] to-[#FD6D53]"
                icon={<FaDatabase />}
                title="Buy Server Database Slots"
                order={
                  <OrderForm
                    name="Database Slots"
                    api_url="/store/databases"
                    suffix=" Database Slot(s)"
                    cost={pricing.databases}
                    desc={pricing.databases + ' Coins Per Slot'}
                  />
                }
              />

              <Resource
                extra="mb-5"
                bgBox="bg-gradient-to-br from-[#92FE9D] to-[#00C9FF]"
                icon={<FaEthernet />}
                title="Buy Extra Server Ports Slots"
                order={
                  <OrderForm
                    name="Extra Server Ports Slots"
                    api_url="/store/ports"
                    suffix=" Extra Server Port(s)"
                    cost={pricing.extra_ports}
                    desc={pricing.extra_ports + ' Coins Per Slot'}
                  />
                }
              />
              <Resource
                bgBox="bg-gradient-to-br from-[#FF9966] to-[#FF5E62]"
                icon={<FaBoxes />}
                title="Buy Server Backup Slots"
                order={
                  <OrderForm
                    name="Backup Slots"
                    api_url="/store/backups"
                    suffix=" Backup Slot(s)"
                    cost={pricing.backup_slots}
                    desc={pricing.backup_slots + ' Coins Per Slot'}
                  />
                }
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Store;
