import React, { useState, useEffect } from 'react';
import api from '../../../api.js';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import InputField from 'components/fields/InputField';

const CheckoutFormServerSlots = (props) => {
  const { name } = props;
  const auth = useAuth();
  const id = auth.user.id;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gateway, setGateway] = useState({});
  useEffect(() => {
    api
      .get(`/settings/gateway/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setGateway(response.data.settings);
        }
      })
      .catch((error) => {
        console.error('Error getting Branding Information: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const server_slots = form.get('server_slots');

    api
      .post('/checkout/paypal/server_slots', {
        id,
        server_slots,
      })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          window.location.href = data.redirect_url;
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Buying extra server slots was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  return (
    <div className="flex h-full w-full flex-col items-center rounded-[20px] px-[1px] pt-[3px] dark:bg-navy-900 sm:px-[150px] lg:px-[0px] xl:px-[35px]">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <h1 className="mx-auto mt-14 text-[35px] font-bold text-navy-700 dark:text-white md:px-[10px] lg:text-[30px] xl:text-[53px]">
            {name}
          </h1>
          <form
            onSubmit={handleSubmit}
            className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
          >
            <p className="mb-9 ml-1 text-base text-gray-600">
              Purchase Server Slots using PayPal / Credit Card / Debit Card, $
              {gateway.server_slots} Per Extra Server Slot
            </p>
            {/* Amount of Server Slots */}
            <InputField
              field_name="server_slots"
              extra="mb-3"
              label={'Amount of Server Slots to Purchase'}
              placeholder="2"
              id="server_slots"
              type="number"
              min="1"
              required
            />
            {buttonLoading ? (
              <button
                className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                disabled
              >
                <Spinner
                  size="xl"
                  thickness="2px"
                  style={{ height: 14, width: 14 }}
                />
              </button>
            ) : (
              <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                Checkout with PayPal
              </button>
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default CheckoutFormServerSlots;
