import { useState } from 'react';
import api from '../../../api.js';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import InputField from 'components/fields/InputField';

const OrderForm = (props) => {
  const auth = useAuth();
  const id = auth.user.id;
  const { name, api_url, suffix, cost, desc } = props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const resource = form.get('resource');
    const coins = Math.ceil(cost * resource);
    api
      .post(api_url, { id, resource })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `You have sucessfully bought ${resource}${suffix} for ${coins} Coins.`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Purchase was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Purchase was not successful! Please contact your administrator.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <p className="mb-9 ml-1 text-base text-gray-600">{desc}</p>
      {/* Email */}
      <InputField
        field_name="resource"
        extra="mb-3"
        label={'Amount of ' + name + ' to Buy'}
        placeholder="300"
        id="resource"
        type="number"
        min="1"
        required
      />
      {buttonLoading ? (
        <button
          className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          disabled
        >
          <Spinner
            size="xl"
            thickness="2px"
            style={{ height: 14, width: 14 }}
          />
        </button>
      ) : (
        <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Buy {name}
        </button>
      )}
    </form>
  );
};

export default OrderForm;
