import Card from 'components/card';
import InputField from 'components/fields/InputField';
import api from '../../../api.js';
import { useAdminAuth } from 'contexts/AuthContextAdmin';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const UpdateUser = () => {
  useAdminAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has('id') === false) {
    Swal.fire({
      title: 'Error!',
      text: `Invalid User ID! Please try again.`,
      icon: 'error',
      confirmButtonText: 'OK',
    }).then(() => {
      window.location.replace('/admin/dashboard');
    });
  }
  const id = queryParams.get('id');
  const [buttonloading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [resources_data, setResourcesData] = useState({});
  useEffect(() => {
    api
      .get(`/admin/user/get/${id}`)
      .then((response) => {
        if (response.data.status === 'success') {
          setUser(response.data.user);
          setResourcesData(JSON.parse(response.data.user.resources_data));
        }
      })
      .catch((error) => {
        console.error('Error getting User: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const firstname = form.get('firstname');
    const lastname = form.get('lastname');
    const email = form.get('email');
    const coins = form.get('coins');
    const server_slots = form.get('server_slots');
    const cpu = form.get('cpu');
    const ram = form.get('ram');
    const disk = form.get('disk');
    const dbs = form.get('databases');
    const extra_ports = form.get('ports');
    const backup_slots = form.get('backups');
    const resources_data = JSON.stringify({
      server_slots,
      cpu,
      ram,
      disk,
      dbs,
      extra_ports,
      backup_slots,
    });
    api
      .post('/admin/user/update', {
        id,
        firstname,
        lastname,
        email,
        coins,
        resources_data,
      })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `User has been edited, you will now be redirected to the dashboard.`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/admin/dashboard';
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Error while editing the user: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Error while editing the user, please try again.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      });
  };
  return (
    <div className="mt-3 h-full w-full">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
          <Card extra={'h-full mx-auto pb-3'}>
            <form onSubmit={handleSubmit}>
              <div className="rounded-[20px]">
                <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
                  {/* Header */}
                  <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                    User Info
                  </h4>
                  <div className="mb-3 flex w-full items-center justify-center gap-4">
                    <div className="w-1/2">
                      <InputField
                        field_name="firstname"
                        extra="mb-3"
                        label="First Name"
                        value={user.firstname}
                        id="firstname"
                        type="text"
                        required
                      />
                    </div>

                    <div className="w-1/2">
                      <InputField
                        field_name="lastname"
                        extra="mb-3"
                        label="Last Name"
                        value={user.lastname}
                        id="lastname"
                        type="text"
                        required
                      />
                    </div>
                  </div>
                  <InputField
                    field_name="email"
                    variant="auth"
                    extra="mb-3"
                    label="Email"
                    value={user.email}
                    id="email"
                    type="email"
                    required
                  />
                  <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                    User Coins and Server Slots
                  </h4>
                  <div className="mb-3 flex w-full items-center justify-center gap-4">
                    <div className="w-1/2">
                      <InputField
                        field_name="coins"
                        extra="mb-3"
                        label="Coins*"
                        value={user.coins}
                        id="coins"
                        type="number"
                        required
                      />
                    </div>

                    <div className="w-1/2">
                      <InputField
                        field_name="server_slots"
                        extra="mb-3"
                        label="Server Slots*"
                        value={resources_data.server_slots}
                        id="server_slots"
                        type="number"
                        required
                      />
                    </div>
                  </div>
                  <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                    User Resources (Currently Available Resources, Not Counting
                    Resources in Use)
                  </h4>
                  <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-3">
                    <InputField
                      extra="mb-3"
                      label="CPU (%)"
                      value={resources_data.cpu}
                      id="cpu"
                      field_name="cpu"
                      type="number"
                      min="0"
                      required
                    />
                    <InputField
                      extra="mb-3"
                      label="RAM (MB)"
                      value={resources_data.ram}
                      id="ram"
                      field_name="ram"
                      type="number"
                      min="0"
                      required
                    />
                    <InputField
                      extra="mb-3"
                      label="Disk (MB)"
                      value={resources_data.disk}
                      id="disk"
                      field_name="disk"
                      type="number"
                      min="0"
                      required
                    />
                  </div>
                  <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-3">
                    <InputField
                      extra="mb-3"
                      label="Database Slots"
                      value={resources_data.dbs}
                      id="databases"
                      field_name="databases"
                      type="number"
                      min="0"
                      required
                    />
                    <InputField
                      extra="mb-3"
                      label="Extra Ports (Allocations)"
                      value={resources_data.extra_ports}
                      id="ports"
                      field_name="ports"
                      type="number"
                      min="0"
                      required
                    />
                    <InputField
                      extra="mb-3"
                      label="Server Backup Slots"
                      value={resources_data.backup_slots}
                      placeholder="5"
                      id="backups"
                      field_name="backups"
                      type="number"
                      min="0"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 flex flex-col justify-between px-3 pt-2 md:flex-row md:px-8">
                {buttonloading ? (
                  <button
                    className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                    disabled
                  >
                    <Spinner
                      size="xl"
                      thickness="2px"
                      style={{ height: 14, width: 14 }}
                    />
                  </button>
                ) : (
                  <button className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2">
                    Update User
                  </button>
                )}
              </div>
            </form>
          </Card>
        </div>
      )}
    </div>
  );
};

export default UpdateUser;
