import Information from './components/Information';
import Password from './components/Password';
import Profile from './components/Profile';
import TwoFactor from './components/TwoFactor';
import Linked from './components/Linked';
import { useLocation } from 'react-router-dom';
import api from '../../../api.js';
import { useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';

const Setting = () => {
  const auth = useAuth();
  const user_id = auth.user.id;
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (code) {
      api.post('/account/discord/link', { user_id, code }).then(({ data }) => {
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `You have successfully linked your discord account.`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/account/settings';
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Login was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      });
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-md lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        <Profile />
        <Information />
      </div>

      <div className="flex flex-col gap-5">
        <TwoFactor />
        <Password />
      </div>

      <div className="flex flex-col gap-5">
        <Linked />
      </div>
    </div>
  );
};

export default Setting;
