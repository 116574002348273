import React, { useState, useEffect } from 'react';
import J4CTable from './components/J4CTable';
import { Spinner } from '@chakra-ui/spinner';
import api from '../../api.js';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';

const Dashboard = () => {
  const auth = useAuth();
  const id = auth.user.id;
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [j4c, setj4c] = useState([]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (code) {
      api.post('/earn/j4c/refresh', { id, code }).then(({ data }) => {
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `You have successfully claimed all pending rewards.`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/earn/j4c';
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Claiming rewards was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      });
    }
    //eslint-disable-next-line
  }, []);
  const columns = [
    {
      Header: 'NAME',
      accessor: 'name',
    },
    {
      Header: 'COINS',
      accessor: 'coins',
    },
    {
      accessor: 'invite',
    },
    {
      Header: 'ACTIONS',
      accessor: 'actions',
    },
  ];
  useEffect(() => {
    api
      .get(`/earn/j4c/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setj4c(response.data.j4c);
        }
      })
      .catch((error) => {
        console.error('Error getting J4C Servers: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="h-full w-full rounded-md">
      {/* Reserved for future statistics */}
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-md xl:flex-row">
          <div className="h-full w-full rounded-md">
            <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
              <div className="col-span-6 h-full w-full rounded-md 3xl:col-span-4">
                <div className="h-full w-full rounded-[14px]  ">
                  <J4CTable columnsData={columns} tableData={j4c} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
