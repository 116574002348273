import SetUp from './Linked_actions.jsx';
import Card from 'components/card';
import { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import api from '../../../../api';

const Linked = () => {
  const auth = useAuth();
  const id = auth.user.id;
  const [authData, setAuthData] = useState({});
  useEffect(() => {
    api
      .get(`/account/auth_data/${id}`)
      .then(({ data }) => {
        if (data.status === 'success') {
          setAuthData(data.auth_data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <Card extra={'w-full px-8 py-8'}>
      {/* Header */}
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Linked Accounts
        </h4>
      </div>
      {/* content */}
      {authData.discord ? (
        authData.discord.status === 'active' ? (
          <SetUp
            name="Discord Account"
            value="Enabled"
            actionName="Unlink"
            action="unlink"
            py=" py-[20px]"
            border="border-b"
            id={id}
          />
        ) : (
          <SetUp
            name="Discord Account"
            value="Disabled"
            actionName="Link"
            action="link"
            py=" py-[20px]"
            border="border-b"
            id={id}
          />
        )
      ) : (
        <SetUp
          name="Discord Account"
          value="Disabled"
          actionName="Link"
          py=" py-[20px]"
          border="border-b"
          id={id}
        />
      )}
    </Card>
  );
};

export default Linked;
