import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import React from 'react';
import api from '../../api';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { Spinner } from '@chakra-ui/spinner';

function SetPassword() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has('code') === false) {
    Swal.fire({
      title: 'Error!',
      text: `No Password reset code was given!`,
      icon: 'error',
      confirmButtonText: 'OK',
    }).then(() => {
      window.location.replace('/auth/login');
    });
  }
  const [sbuttonloading, setSbuttonLoading] = React.useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setSbuttonLoading(true);
    let code = queryParams.get('code');
    const form = new FormData(e.target);
    const password = form.get('password');
    const confirm_password = form.get('confirm_password');

    if (password === confirm_password) {
      api
        .post('/auth/password/set', { password, code })
        .then(({ data }) => {
          setSbuttonLoading(false);
          if (data.status === 'success') {
            Swal.fire({
              title: 'Success!',
              text: `Password have been successfully reset!`,
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              window.location.replace('/auth/login');
            });
          } else if (data.status === 'error') {
            Swal.fire({
              title: 'Error!',
              text: `Password reset was not successful! Error: ${data.message}`,
              icon: 'error',
              confirmButtonText: 'OK',
            });
          } else {
            Swal.fire({
              title: 'Error!',
              text: `Password reset was not successful! Please contact your administrator.`,
              icon: 'error',
              confirmButtonText: 'OK',
            }).then(() => {
              window.location.replace('/auth/login');
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSbuttonLoading(false);
      Swal.fire({
        title: 'Error!',
        text: `Confirm password is different from password!`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <form
              onSubmit={handleSubmit}
              className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
            >
              <h2 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
                Reset Password
              </h2>
              {/* Password */}
              <InputField
                field_name="password"
                variant="auth"
                extra="mb-3"
                label="Password"
                placeholder="*********"
                id="password"
                type="password"
              />
              {/* Confirm Password */}
              <InputField
                field_name="confirm_password"
                variant="auth"
                extra="mb-3"
                label="Confirm Password"
                placeholder="*********"
                id="confirm_password"
                type="password"
              />
              {/* button */}
              {sbuttonloading ? (
                <button
                  className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled="true"
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Set Password
                </button>
              )}
            </form>
          </div>
        </div>
      }
    />
  );
}

export default SetPassword;
