import React from 'react';
import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import api from '../../../api.js';
import { useAdminAuth } from 'contexts/AuthContextAdmin';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';

function AdminLogin() {
  const auth = useAdminAuth();
  const [sbuttonloading, setSbuttonLoading] = React.useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSbuttonLoading(true);
    const form = new FormData(e.target);
    const email = form.get('email');
    const password = form.get('password');
    api
      .post('/auth/admin/login', { email, password })
      .then(({ data }) => {
        setSbuttonLoading(false);
        if (data.status === 'success') {
          auth.login(data);
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Login was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Login was not successful! Please contact your administrator.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Default
      maincard={
        <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <form
              onSubmit={handleSubmit}
              className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
            >
              <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                Admin Login
              </h3>
              <p className="mb-9 ml-1 text-base text-gray-600">
                Enter your email and password to sign in!
              </p>
              {/* Email */}
              <InputField
                field_name="email"
                variant="auth"
                extra="mb-3"
                label="Email / Username*"
                placeholder="example@gmail.com / example"
                id="email"
                type="text"
              />

              {/* Password */}
              <InputField
                field_name="password"
                variant="auth"
                extra="mb-3"
                label="Password*"
                placeholder="*********"
                id="password"
                type="password"
              />
              {sbuttonloading ? (
                <button
                  className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Login
                </button>
              )}
            </form>
          </div>
        </div>
      }
    />
  );
}

export default AdminLogin;
