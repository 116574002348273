// components
import RedeemForm from './components/RedeemForm';
import Banner from './components/Banner';

//assets
import NftLarge1 from 'assets/img/nfts/NftLarge1.png';

const RedeemCoupon = () => {
  return (
    <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
      <div className="grid h-full w-full grid-cols-6 gap-[20px]">
        <div className="col-span-6 lg:col-span-3">
          <Banner image={NftLarge1} />
        </div>

        <div className=" col-span-6 lg:!col-span-3">
          <div className=" xl:px-16">
            <RedeemForm name="Redeem Coupon" />
          </div>
          <div className="pt-4"></div>
        </div>
      </div>
    </div>
  );
};

export default RedeemCoupon;
