import React, { useState, useEffect } from 'react';
import { useAdminAuth } from 'contexts/AuthContextAdmin';
import ServersTable from './components/ServersTable';
import api from '../../../api';
import { Spinner } from '@chakra-ui/spinner';

const Servers = () => {
  useAdminAuth();
  const [loading, setLoading] = useState(true);
  const [servers, setServers] = useState([]);
  const columns = [
    {
      Header: 'ID',
      accessor: 'server_id',
    },
    {
      Header: 'SERVER IDENTIFIER',
      accessor: 'server_identifier',
    },
    {
      Header: 'OWNER ID',
      accessor: 'owner_id',
    },
    {
      Header: 'COST',
      accessor: 'cost',
    },
    {
      Header: 'NEXT BILLING DATE',
      accessor: 'next_billing_date',
    },
    {
      Header: 'STATUS',
      accessor: 'suspended',
    },
    {
      Header: 'ACTIONS',
      accessor: 'actions',
    },
  ];
  useEffect(() => {
    api
      .get(`/admin/servers/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setServers(response.data.servers);
        }
      })
      .catch((error) => {
        console.error('Error getting Servers: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="h-full w-full rounded-md">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <div className="mt-3 h-full w-full rounded-[14px]  ">
            <ServersTable columnsData={columns} tableData={servers} />
          </div>
        </>
      )}
    </div>
  );
};

export default Servers;
