/* eslint-disable */

import { HiX } from 'react-icons/hi';
import Links from './components/Links';
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import routes from 'routes';
import Card from 'components/card';
import { Link } from 'react-router-dom';
import api from '../../api.js';
import { Spinner } from '@chakra-ui/spinner';
import { useState, useEffect } from 'react';

function SidebarHorizon(props) {
  const { open, onClose, variant, mini, hovered, setHovered } = props;
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState({});
  useEffect(() => {
    api
      .get(`/settings/brand/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setBrand(response.data.settings);
        }
      })
      .catch((error) => {
        console.error('Error getting Branding Information: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`sm:none ${
        mini === false
          ? 'w-[285px]'
          : mini === true && hovered === true
          ? 'w-[285px]'
          : 'w-[285px] xl:!w-[120px]'
      } duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
        variant === 'auth' ? 'xl:hidden' : 'xl:block'
      } ${open ? '' : '-translate-x-[105%]'}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Card
        extra={`ml-3 w-full h-[96.5vh] shadow-none !border-0 sm:mr-4 sm:my-4 m-7 !rounded-[10px] lg:!bg-white-500 xl:bg-[transparent] dark:lg:!bg-[transparent] xl:dark:bg-[transparent]`}
      >
        {loading ? (
          <div className="flex h-32 items-center justify-center">
            <Spinner
              size="xl"
              thickness="6px"
              style={{ height: 100, width: 100 }}
            />
          </div>
        ) : (
          <>
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={
                mini === false
                  ? renderView
                  : mini === true && hovered === true
                  ? renderView
                  : renderViewMini
              }
            >
              <div className="flex h-full flex-col justify-between">
                <div>
                  <span
                    className="absolute right-6 top-4 block cursor-pointer xl:hidden"
                    onClick={onClose}
                  >
                    <HiX />
                  </span>
                  <div className={`ml-[52px] mt-[44px] flex items-center `}>
                    <div
                      className={`ml-1 mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                        mini === false
                          ? 'block'
                          : mini === true && hovered === true
                          ? 'block'
                          : 'hidden'
                      }`}
                    >
                      <Link to="/">
                        <img
                          src={brand.assets.logo_url}
                          alt={brand.company_name}
                          height={64}
                          width={64}
                        />
                      </Link>
                    </div>
                    <div
                      className={`ml-1 mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                        mini === false
                          ? 'hidden'
                          : mini === true && hovered === true
                          ? 'hidden'
                          : 'block'
                      }`}
                    >
                      H
                    </div>
                  </div>
                  <div className="mb-7 mt-[58px] h-px bg-gray-200 dark:bg-white/10" />
                  {/* Nav item */}
                  <ul>
                    <Links mini={mini} hovered={hovered} routes={routes} />
                  </ul>
                </div>
              </div>
            </Scrollbars>
          </>
        )}
      </Card>
    </div>
  );
}

export default SidebarHorizon;
